import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageAboutUsHero from "../components/imageAboutUsHero"
import Motto from "../components/motto"

const HeroWrapper = styled.section`
  margin-top: 96.23px;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
  margin-left: -15px;
  margin-right: -15px;
  @media (min-width: 992px) {
    padding: 0 40px;
  }
  @media (min-width: 1280px) {
    padding: 0;
  }
`
const ArticleWrapper = styled.article`
  max-width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    padding: 20px 0;
    @media (min-width: 768px) {
      padding: 40px 0;
    }
    @media (min-width: 992px) {
      padding: 60px 0;
    }
  }

  p {
    font-size: 16px;
    margin-top: 5px;
    text-align: justify;
    padding: 20px 0;
    @media (min-width: 768px) {
      padding: 15px;
    }
    @media (min-width: 992px) {
      padding: 10px 15px;
    }
  }

  h1 {
    margin: 0;
    font-size: 26px;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 39px;
    }
    @media (min-width: 992px) {
      font-size: 42px;
    }
  }
`

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="O nas" />
      <HeroWrapper>
        <ImageAboutUsHero />
        <div className="container">
          <ContentWrapper>
            <ArticleWrapper>
              <header>
                <h1>O nas</h1>
              </header>
              <div>
                <p>
                  Jesteśmy biurem projektowym, które specjalizuje się w
                  projektowaniu systemów sterowania ruchem kolejowym i kontroli
                  dla kolei konwencjonalnych, kolei dużych prędkości oraz metra.
                </p>
                <p>
                  Nasze rozwiązania opierają się na bogatym doświadczeniu
                  zdobytym w Polsce jak i za granicą. Gwarancją naszego
                  doświadczenia jest przynależność do światowego Stowarzyszenia
                  Inżynierów Sterowania Ruchem Kolejowym (IRSE) jak również
                  posiadanie nieograniczonych uprawnień budowlanych do
                  projektowania w zakresie urządzeń sterowania ruchem kolejowym
                  w Polsce.
                </p>
                <p>
                  W naszej pracy wykorzystujemy najnowsze rozwiązania i
                  technologie z zakresu inżynierii systemów i ich integracji.
                  Implementacja procesów zarządzania i kontroli wymaganiami
                  projektowymi oraz ryzkiem pozwala na tworzenie efektywnych
                  rozwiązań pod względem technicznym i ekonomicznym,
                  bezpieczeństwa jak i w przypadku realizacji prac.
                </p>
              </div>
            </ArticleWrapper>
          </ContentWrapper>
        </div>
      </HeroWrapper>
      <Motto />
    </Layout>
  )
}

export default AboutUs
